import React from "react";
import { Link } from "react-router-dom";
import Q_logo from "../Images/Qliqle_logo.svg";
import Google_play from "../Images/Google_Play_store_icon.svg";
import { useLocation } from "react-router-dom";

function Bottom() {
  const location = useLocation();
  const playstoreUrl = process.env.REACT_APP_PLAYSTORE_URL;

  return (
    <div className="flex justify-center items-center w-full abspulte bottom-0   bg-gradient-to-r from-[#0F0F0F] to-[#033E83] overflow-hidden">
      <div className="flex flex-col w-full  sm:px-3 md:max-w-[772px] md:ml-[30px] md:mr-[30px] md:px-0 lg:max-w-[1100px] lg:px-0 lg:ml-[50px] lg:mr-[50px] xl:px-0 xl:max-w-[1440px] xl:ml-[150px] xl:mr-[150px] 2xl:px-0 2xl:max-w-[1440px]">
        <div className="my-4 lg:mb-[64px] lg:mt-[80px] flex  flex-col sm:px-0 sm:flex-col lg:max-h-[85px] md:px-0 lg:px-0 lg:flex-row lg:justify-between xl:px-0 2xl:px-0 max-w-full sm:justify-between">
          <div className="flex flex-row w-full justify-between  md:max-w-100 md:px-0 lg:justify-normal lg:max-w-[284px] 2xl:px-0 ">
            <div className="py-[20px]">
              <Link to="/">
                <img
                  src={Q_logo}
                  alt="Qliqle_logo"
                  className="w-[45px] h-[45px] gap-0 mr-[40px]"
                />
              </Link>
            </div>
            <div className="flex flex-row items-center justify-between w-[149px] lg:pt-[30px] lg:pb-[31px]">
              <p
                className="text-[#FFFFFF] text-[16px] pr-[40px] max-w-[47px] min-h-[24px] tracking-[0.3px] font-normal"
                style={{ fontFamily: "Inter" }}
              >
                <Link to="/about">About</Link>
              </p>
              <p
                className="text-[#FFFFFF] text-[16px] tracking-[0.3px] font-normal"
                style={{ fontFamily: "Inter" }}
              >
                <Link to="/contact">Contact</Link>
              </p>
            </div>
          </div>
          <div className="flex flex-col text-white  mt-[55px] mb-[50p] lg:mb-0 lg:mt-0 ">
            <h1
              className="text-[16px] min-h-[24px] font-normal tracking-[0.3px] text-[#FFFFFF]"
              style={{ fontFamily: "Inter" }}
            >
              Would you like to find out more?
            </h1>

            <div className="flex items-center w-full max-w-[416px] bg-[#A5A5A5] bg-opacity-20 h-12 border justify-between mt-2.5 border-[#B3BDC7] rounded-[38px]">
              <input
                type="email"
                placeholder="Your email address..."
                className="outline-none bg-transparent w-full pl-5 h-full  placeholder-[#A5A5A5] text-[#FFFFFF]"
              />
              <button
                className="bg-[#C685D6] h-full rounded-[38px] min-w-[157px] text-[#FFFFFF]"
                type="submit"
              >
                Learn more
              </button>
            </div>

            {/* <div className="flex flex-row justify-between items-center mt-[10px] lg:mb-[1.5px] w-full sm:max-w-[300px] md:max-w-[416px]  lg:min-w-[416px] min-h-[48px] rounded-[38px] bg-zinc-800 border-[1px] border-solid border-[#B3BDC7] overflow-hidden ">
              <input
                type="email"
                placeholder="Your email address..."
                className=" flex-grow w-full bg-transparent border-none text-[16px] focus:outline-none text-white placeholder-[#92989F] text-left placeholder-line-h-[24px] sm:placeholder-line-max-w-[239px] placeholder-center pl-[10px] md:pl-[20px] h-full  items-center"
              />
              <button
                type="submit"
                className=" bg-[#C685D6] text-[#FFFFFF] py-[12px] rounded-[38px] sm:rounded-[38px] sm:w-[120px] md:w-[157px] flex justify-center items-center"
              >
                <span
                  className=" min-w-[89px] min-h-[24px] font-normal text-[16px] tracking-[0.3px]"
                  style={{ fontFamily: "Inter" }}
                >
                  Learn more
                </span>
              </button>
            </div> */}
          </div>
        </div>

        <div className=" flex w-full items-center px-3 sm:px-0 md:pl-2 md:px-0 lg:max-w-[1100px] lg:px-0  xl:max-w-[1415px] xl:px-0 2xl:min-w-full 2xl:px-0 min-h-[2px] ">
          <hr
            className=" w-full md:max-w-[782px] md:px-2 lg:max-w-[1100px] xl:max-w-[1415px] border-t border-[#71777D] 2xl:max-w-full min-h-[2px]"
            style={{ strokeWidth: "2px" }}
          />
        </div>

        <div className="flex sm:flex-col lg:flex-row w-full justify-between  md:max-w-[782px] lg:max-w-[1100px] xl:px-0 xl:max-w-[1415px] 2xl:max-w-100 2xl:px-0 h-full">
          <div className=" flex flex-col md:flex-row  mt-[24px] mb-[67px] lg:mt-[33px] lg:mb-[101px]">
            <div className="flex flex-row">
              <Link to="/terms/TERMSOFSERVICE">
                <p
                  className="text-[#92989F]  pr-4 text-[12px] min-h-[16px] tracking-[0.3px] max-w-[260px]"
                  style={{ fontFamily: "Inter" }}
                >
                  Terms & Conditions
                </p>
              </Link>
              <p className="text-[#92989F] pr-4 text-[12px] min-h-[16px]">| </p>
              <Link to="/terms/PRIVACYPOLICY">
                <p
                  className="text-[#92989F] pr-4 text-[12px] min-h-[16px] max-w-[260px] tracking-[0.3px]"
                  style={{ fontFamily: "Inter" }}
                >
                  Privacy Policy
                </p>
              </Link>
              <p className="text-[#92989F]  text-[12px] min-h-[16px]">| </p>
            </div>
          </div>
          {location.pathname !== "/ios-info" ? (
            <div className="flex flex-row items-center bg-blue ">
              <Link to={playstoreUrl}>
                <img
                  src={Google_play}
                  alt="google_play_store"
                  className="mr-[50px] min-w-[29px] min-h-[32px] lg:mt-[26px] mb-7"
                />
              </Link>
              <Link to="/feedback">
                <button
                  type="button"
                  className="bg-[#FFBB1C] text-center rounded-md min-w-[115px] min-h-[50px] mt-[1px] lg:mt-[16px] mb-7 "
                  style={{ fontFamily: "Inter" }}
                >
                  Feedback
                </button>
              </Link>
            </div>
          ) : (
            <div className="flex flex-row items-center invisible  ">
              <Link to="">
                <img
                  src={Google_play}
                  alt="google_play_store"
                  className="mr-[50px] min-w-[29px] min-h-[32px] lg:mt-[26px] m-0"
                />
              </Link>
              <Link to="/feedback">
                <button
                  type="button"
                  className="bg-[#FFBB1C] text-center rounded-md min-w-[115px] min-h-[50px] mt-[1px] lg:mt-[16px] m-0 "
                  style={{ fontFamily: "Inter" }}
                >
                  Feedback
                </button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Bottom;
