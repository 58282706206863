import React from 'react'
import { Link,useLocation } from 'react-router-dom';
import Q_Logo from '../Images/Qliqle_logo.svg';
import { useState,useEffect } from 'react';

function Header() {

  const[clicked,setClicked]=useState(false)
  const { pathname } = useLocation();

  useEffect(() => {
    if (clicked) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [clicked]);

  const handleClicked=()=>{
    setClicked(prevClicked=>!prevClicked)
  }

  const handleCloseMenu = () => {
    setClicked(false);
  };
    //bg-[#0000000F]

  return (
<header className="bg-gradient-to-r from-[#0F0F0F] to-[#033E83] flex justify-center items-center z-30">
      <nav className="flex flex-row w-full h-[58px] md:h-[87px] items-center justify-between sm:justify-between sm:px-[20px] md:px-0 md:justify-between md:max-w-[772px] md:ml-[30px] md:mr-[30px] md:pl-0 md:pr-0 lg:justify-between lg:max-w-[1100px] lg:ml-[50px] lg:mr-[50px] lg:pl-0 lg:pr-0 xl:pr-0 xl:pl-0  xl:justify-between xl:max-w-[1440px] xl:mr-[150px] xl:ml-[150px] 2xl:max-w-[1440px] 2xl:pl-0 2xl:justify-between gap-0">
        <div className="flex items-start">
        <Link to="/"><img src={Q_Logo} alt="Qliqle_logo " className="w-[53px] h-[55px] gap-0" /></Link>
        </div>
     
        <div className="text-[#FFFFFF] w-50vw h-[38px]">
  <ul id="navbar" className={clicked ? "#navbar active" : "navbar whitespace-nowrap"}>
    <li className={`list_items text-[18px] text-left h-[38px] sm:px-4  w-auto tracking-[0.94px] lg:w-44 mr-[8px]   pt-2 pb-2 lg:px-4  text-[#FFFFFF] shadow-xl rounded-md ${
      pathname === '/' ? 'text-[#FFFFFF] bg-[#024CA3] px-1 font-semibold' : 'text-[#FFFFFF]'
    }`} onClick={handleCloseMenu}>
      <Link to="/"><span className="h-[22px]" style={{ fontFamily: 'Inter' }}>Home</span></Link>
    </li>
    
    <li className={`list_items text-[18px] text-left h-[38px] sm:px-4  w-auto tracking-[0.94px] lg:w-44 mr-[8px]   pt-2 pb-2 lg:px-4  text-[#FFFFFF] shadow-xl rounded-md ${
      pathname === '/about' ? 'text-[#FFFFFF] bg-[#024CA3] px-1 font-semibold' : 'text-[#FFFFFF]'
    }`} onClick={handleCloseMenu}>
      <Link to="/about"><span className="h-[22px]" style={{ fontFamily: 'Inter' }}>About Us</span></Link>
    </li>
    
    <li className={`list_items text-[18px] text-left h-[38px] sm:px-4  w-auto tracking-[0.94px] lg:w-44 mr-[8px]   pt-2 pb-2 lg:px-4  text-[#FFFFFF] shadow-xl rounded-md ${
      pathname === '/contact' ? 'text-[#FFFFFF] bg-[#024CA3] px-1 font-semibold' : 'text-[#FFFFFF]'
    }`} onClick={handleCloseMenu}>
      <Link to="/contact"><span className="h-[22px]" style={{ fontFamily: 'Inter' }}>Contact Us</span></Link>
    </li>
    
    <li className={`list_items text-[18px] text-left h-[38px] sm:px-4  w-auto tracking-[0.94px] lg:w-44 mr-[8px]   pt-2 pb-2 lg:px-2  text-[#FFFFFF] shadow-xl rounded-md ${
      pathname === '/download-now' ? 'text-[#FFFFFF] bg-[#024CA3] px-1 font-semibold' : 'text-[#FFFFFF]'
    }`} onClick={handleCloseMenu}>
      <Link to="/download-now"><span className="h-[22px]" style={{ fontFamily: 'Inter' }}>Download Qliqle</span></Link>
    </li>
  </ul>
</div>

      <div  className="text-[#FFFFFF] block px-0  lg:hidden" onClick={handleClicked}>
        <i   className={clicked?"fas fa-times text-[24px] cursor-pointer":"fas fa-bars text-[24px] cursor-pointer"}></i>
      </div>
      </nav>

    </header>
  )
}

export default Header


