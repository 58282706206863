import React, { Suspense, lazy, useState, useMemo, useCallback } from "react";
import { Outlet } from "react-router-dom";
import ThemeSuspence from "../components/ThemeSuspence";
import { useQuery } from "react-query";
import { getUserPhotos } from "../lib/getUserPhotosApi";
function GetUserPhotosRoutes() {
  const [token, setToken] = useState(null);
  const [albumSegment, setAlbumSegment] = useState(null);
  const [userSegment, setUserSegment] = useState(null);
  const [notAuthorized, setNotAuthorized] = useState(false);

  const updateToken = useCallback((newToken) => {
    // console.log("GetUserPhotosRoutes: Token updated ->", newToken);
    setToken(newToken);
  }, []);
  useQuery(["userPhotos", token], () => getUserPhotos(token), {
    enabled: !!token && !notAuthorized,
    retry: false, // Run only if token exists
    onSuccess: (resp) => {
      setAlbumSegment(resp.data.albumSegment);
      setUserSegment(resp.data.userSegment);
      setNotAuthorized(false);
    },
    onError: () => {
      console.log("inside the code");
      setNotAuthorized(true);
      setToken(null);
    }
  });
  const contextValue = useMemo(() => {
    return { token, albumSegment, userSegment, updateToken, notAuthorized };
  }, [token, albumSegment, userSegment, updateToken, notAuthorized]);

  return (
    <Suspense fallback={<ThemeSuspence />}>
      <Outlet context={contextValue} />
    </Suspense>
  );
}

export default GetUserPhotosRoutes;
