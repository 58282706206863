// src/routes/feedBackUrl.js
const APIMapping = {
  // getUserPhotos: "/qliqleWebsite/userPhotos", //localhost api
  // getUserPhotos: "/qliqleWebsite/getAlbumPhotos" //localhost album api
  getUserPhotos: "/dashboard/qliqleWebsite/getAlbumPhotos" //test album api
  // getUserPhotos: "/dashboard/qliqleWebsite/userPhotos" //test api
};

// const BASE_URL = "http://localhost:8080/api/v1"; //localhost api
const BASE_URL = "https://test.qliqle.com/api/v1"; //test api

const getKrakendApiMap = (name) => {
  if (!APIMapping[name]) {
    throw new Error(`No API mapping found for ${name}`);
  }

  return `${BASE_URL}${APIMapping[name]}`;
};

export default getKrakendApiMap;
