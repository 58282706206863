import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { lazy, Suspense } from "react";
import Layout from "./components/Layout/Layout";
import GetUserPhotosRoutes from "./routes/GetUserPhotosRoutes";

const Home = lazy(() => import("./components/Home/Home"));
const TermsConditionsPrivacy = lazy(() =>
  import("./components/Terms_Conditions_Privacy/Terms_C_Privacy")
);
const FeedBackForm = lazy(() => import("./components/Pages/FeedBackForm"));
const AboutUs = lazy(() => import("./components/Pages/AboutUs/AboutUs"));
const ContactUsMainPage = lazy(() =>
  import("./components/Pages/ContactUs/ContactUsMainPage")
);
const IosRedirect = lazy(() =>
  import("./components/Pages/IosRedirect/IosRedirect")
);
const Redirect = lazy(() => import("./components/Pages/Redirect"));
const LandingPage = lazy(() => import("./components/Pages/LandingPage"));
const UserPhotos = lazy(() =>
  import("./components/Pages/UserPhotos/UserPhotos")
);
const routes = [
  { path: "/", element: <Home /> },
  { path: "/feedback", element: <FeedBackForm /> },
  { path: "/terms/:id", element: <TermsConditionsPrivacy /> },
  { path: "/about", element: <AboutUs /> },
  { path: "/contact", element: <ContactUsMainPage /> },
  { path: "/ios-info", element: <IosRedirect /> },
  { path: "/redirect", element: <Redirect /> }
];

function App() {
  return (
    <Router>
      <Suspense fallback={<div className="text-center">Loading...</div>}>
        <Routes>
          <Route path="/" element={<Layout />}>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Route>
          <Route path="userPhotos" element={<GetUserPhotosRoutes />}>
            <Route path=":token" element={<UserPhotos />} />
          </Route>
          <Route path="/download-now" element={<LandingPage />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
