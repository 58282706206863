import React, { useState } from "react";
import axios from "axios";
import getKrakendApiMap from "../routes/GetUserPhotosUrl";
export async function getUserPhotos(token) {
  try {
    // console.log(token);
    const url = getKrakendApiMap("getUserPhotos");
    console.log(url);
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {
    console.error("error fetching user photo:", error);

    throw error;
  }
}
