import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import Bottom from "../LandingPages/Bottom";

const Layout = () => (
  <div className="relative min-h-screen flex flex-col">
    <Header />
    <main className="flex-grow">
      <Outlet />
    </main>

    <div className="w-full mt-auto">
      <Bottom />
    </div>
  </div>
);

export default Layout;
